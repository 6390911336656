<template>
  <FormAutocomplete
    :label="label"
    :placeholder="placeholder"
    :dense="dense"
    :solo="solo"
    :disabled="disabled"
    :readonly="readonly"
    :clearable="clearable"
    :chips="multiple"
    :validation="validation"
    :multiple="multiple"
    :options="options"
    @search="onSearch"
    @change="onChange"
    v-model="selected"
    useTemplate
    return-object
  >
    <template v-slot:item="{ item }">
      <slot name="item" :item="item">
        {{ item.text }}
      </slot>
    </template>
  </FormAutocomplete>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import ApiWeb from '../../service/api-web'
import * as _ from 'lodash'
export default {
  name: 'SelectCliente',
  components: { FormAutocomplete },
  props: {
    value: {
      type: [Array, Object],
      default: () => [],
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    endpoint: {
      type: String,
      required: true
    },
    keyid: {
      type: String,
      default: 'id',
      required: false
    },
    keyvalue: {
      type: String,
      default: 'name',
      required: false
    },
    queryname: {
      type: String,
      default: 'search',
      required: false
    },
    multiple: {
      type: Boolean,
      default: true,
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: () => ({
    resultSearch: [],
    selected: []
  }),
  computed: {
    options() {
      return (this.resultSearch || []).map(item => ({
        ...item,
        text: item[this.keyvalue],
        value: item[this.keyid]
      }))
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.initModel(newVal)
        }
      }
    }
  },
  created() {
    this.initModel(this.value)
  },
  methods: {
    initModel(value) {
      if (value) {
        if (Array.isArray(value)) {
          this.selected = (value || []).map(item => ({
            ...item,
            text: item[this.keyvalue],
            value: item[this.keyid]
          }))
        } else {
          this.selected = {
            ...value,
            text: value[this.keyvalue],
            value: value[this.keyid]
          }
        }
      }
      this.onChangeExternalValue()
    },
    onChangeExternalValue() {
      const itemsSelected = Array.isArray(this.selected)
        ? this.selected
        : [this.selected]
      let idsObj = itemsSelected.reduce((objs, obj) => {
        let _id = _.get(obj, this.keyid)
        if (!_.find(this.resultSearch, [this.keyid, _id])) {
          objs.push(_id)
        }
        return objs
      }, [])
      if (idsObj.length > 0) {
        const chunks = _.chunk(idsObj, 50)
        for (let i in chunks) {
          const chunk = chunks[i]
          this.onSearch(chunk)
        }
      }
    },
    onSearch(value) {
      let arrayValues = Array.isArray(value) ? value : [value]
      const initialQuery = this.endpoint.split('?')
      let queryParms = arrayValues.reduce((query, val) => {
        if (query) query += '&'
        query += `${this.queryname}=${val}`
        return query
      }, _.get(initialQuery, '1', ''))
      ApiWeb.api.get(`${initialQuery[0]}?${queryParms}`).then(resp => {
        this.resultSearch = _.uniqBy(
          this.resultSearch.concat(resp.data),
          this.keyid
        )
      })
    },
    onChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
}
</script>
