<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <template v-if="usesDelegatedPriceStorage">
      <Panel lineColor="var(--primary-color)" class="mb-2">
        <v-row>
          <v-col class="text-start" cols="12">
            <h4>
              Cliente de referência para tabela padrão
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" class="ml-2" v-on="on"
                    >far fa-question-circle</v-icon
                  >
                </template>
                <span>
                  A tabela de preços padrão sempre acompanhará aquela do cliente
                  de referência. <br />
                  Sempre que a tabela desse cliente for alterada a nova tabela
                  será marcada automaticamente <br />
                  como a padrão. Utilize algum cadastro cujos preços possam ser
                  mostrados para os novos <br />
                  clientes e clientes deslogados do seu app ou site.
                </span>
              </v-tooltip>
            </h4>
            <FormRadioGroup
              v-if="clientePadrao.isEditando"
              v-model="clientePadrao.usar"
              inline
              :radios="radioClientePadrao"
            />
          </v-col>
        </v-row>
        <v-row v-if="clientePadrao.isEditando">
          <template v-if="clientePadrao.usar">
            <v-col cols="12">
              <SelectCliente
                placeholder="Pesquisar pelo nome, cpf, cnpj ou e-mail"
                label="Selecione o cliente padrão"
                v-model="clientePadrao.cliente"
                :endpoint="endpointClient"
                queryname="search"
                keyid="email"
                keyvalue="nome"
                :multiple="false"
                :clearable="true"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex flex-column align-start">
                    <p style="margin-bottom:0!important">{{ item.nome }}</p>
                    <small v-if="item.cpf"> CPF/CNPJ: {{ item.cpf }} </small>
                  </div>
                </template>
              </SelectCliente>
            </v-col>
            <v-col cols="12" class="d-flex mt-n10">
              <FormSwitch
                v-model="clientePadrao.mostrarPoliticasrClientesSemGrupo"
                label="Disponibilizar as promoções desse cliente para clientes sem tabela de preços padrão"
              />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" class="ml-3 mt-n2" v-on="on"
                    >far fa-question-circle</v-icon
                  >
                </template>
                Os clientes que estão no grupo de preço padrão também irão ver
                as promoções desse cliente.
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="d-flex mt-n10">
              <FormSwitch
                v-model="clientePadrao.mostrarPoliticasModoAnonimo"
                label="Disponibilizar as promoções desse cliente para clientes anônimos/deslogados"
              />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" class="ml-3 mt-n2" v-on="on"
                    >far fa-question-circle</v-icon
                  >
                </template>
                Os clientes em modo anônimo/deslogado irão ver as promoções
                desse cliente.
              </v-tooltip>
            </v-col>
            <v-col cols="12" class="text-start">
              <h4>
                Selecione os tipos de tabela padrão aplicadas ao cliente
                referência
              </h4>
              <v-row no-gutters>
                <FormCheckbox
                  v-for="(tg, i) in tipoGrupo"
                  :key="i"
                  v-model="clientePadrao.defaultSelect"
                  :label="tg"
                  :val="tg"
                  class="mr-10"
                />
              </v-row>
            </v-col>
          </template>
          <v-col class="text-end">
            <FormButton text="Salvar" @click="onUpdateClienteReferencia()" />
            <FormButton
              text="Cancelar"
              type="error"
              class="ml-2"
              @click="cancelarClienteReferencia()"
            />
          </v-col>
        </v-row>
        <v-row v-if="!clientePadrao.isEditando && clientePadrao.cliente">
          <v-col>
            <v-simple-table>
              <tr>
                <th class="text-start">Nome</th>
                <th class="text-start">Documento</th>
                <th class="text-start">Opções</th>
                <th class="text-start">Padrão</th>
                <th class="text-center">Edição</th>
              </tr>
              <tr>
                <td class="text-start">{{ clientePadrao.cliente.nome }}</td>
                <td class="text-start">{{ clientePadrao.cliente.cpf }}</td>
                <td>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <FormCheckbox
                        v-model="
                          clientePadrao.mostrarPoliticasrClientesSemGrupo
                        "
                        readonly
                        class="my-0 py-0 mb-n5"
                        label="Disponibilizar promoções para clientes sem tabela de preço própria"
                      />
                    </v-col>
                    <v-col cols="12">
                      <FormCheckbox
                        v-model="clientePadrao.mostrarPoliticasModoAnonimo"
                        readonly
                        class="my-0 py-0 mb-n5"
                        label="Disponibilizar promoções em modo anônimo/deslogado"
                      />
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <FormCheckbox
                    v-for="(tg, i) in tipoGrupo"
                    :key="i"
                    v-model="clientePadrao.defaultSelect"
                    :label="tg"
                    :val="tg"
                    readonly
                    class="my-0 py-0 mb-n5"
                  />
                </td>
                <td class="text-center">
                  <FormButton
                    text="Editar"
                    @click="clientePadrao.isEditando = true"
                  />
                </td>
              </tr>
            </v-simple-table>
          </v-col>
        </v-row>
      </Panel>
      <Panel lineColor="var(--primary-color)">
        <DataTable
          :headers="headers"
          :endpoint="endpoint"
          sortBy="tipo_grupo"
          :sortDesc="false"
          ref="datatable"
          search
          :filters="filters"
          noHideFilters
          :auto-search="false"
        >
          <template v-slot:tipo_grupo="{ row }">
            <FormCheckbox
              v-for="tg in tipoGrupo"
              :key="tg"
              v-model="row.tipo_grupo"
              :label="tg"
              :val="tg"
              class="my-0 py-0 mb-n6 mt-1"
              @change="updateGrupoPadrao(row, tg)"
            />
          </template>
          <template v-slot:descricao_longa="{ col }">
            <v-row no-gutters>
              <v-col v-for="(c, i) in col.split('\\n')" :key="i" cols="6">{{
                c
              }}</v-col>
            </v-row>
          </template>
          <template v-slot:quant_clientes="{ row }">
            <FormButton
              icon
              :loading="loadingRow[row.codigo_grupo_preco]"
              @click="onClickClient(row)"
            >
              <v-icon size="20">fa-info-circle</v-icon>
            </FormButton>
            <v-chip
              outlined
              class="ml-2 font-weight-bold"
              small
              color="primario"
              >{{ row.quant_clientes }}</v-chip
            >
          </template>
          <template v-slot:ceps="{ row }">
            <FormButton
              icon
              :loading="loadingRow[row.codigo_grupo_preco]"
              @click="onClickCep(row)"
            >
              <v-icon size="20">fa-info-circle</v-icon>
            </FormButton>
          </template>
          <template v-slot:produtos="{ row }">
            <FormButton
              icon
              :loading="loadingRow[row.codigo_grupo_preco]"
              @click="onClickProduct(row)"
            >
              <v-icon size="20">fa-info-circle</v-icon>
            </FormButton>
          </template>
          <template v-slot:idgrupo="{ row }">
            <FormButtonMenu
              icon
              :options="tableOptions"
              :loading="loadingRow[row.codigo_grupo_preco]"
              @click="op => selectOption(row, op)"
            >
              <v-icon size="15">fas fa-ellipsis-v</v-icon>
            </FormButtonMenu>
          </template>
        </DataTable>
      </Panel>
    </template>
    <template v-else>
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title>Enviar Arquivo Tabela de Preços</v-card-title>
          <v-divider />
          <v-card-text class="pa-4 pt-10">
            <FormFile
              label="Arquivo"
              v-model="form.nameCurrentFile"
              @file="onSelectFile"
            >
              <template v-slot:help>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="ml-3"
                      >far fa-question-circle</v-icon
                    >
                  </template>
                  Localize o arquivo de tabela de preços para importação
                </v-tooltip>
              </template>
            </FormFile>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-spacer />
            <FormButton text="Enviar" type="primario" @click="onSubmit()" />
            <FormButton text="Fechar" type="error" @click="dialog = false" />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <Panel lineColor="var(--primary-color)" class="mb-2">
        <FormSelect
          label="Tabela de preços sob configuração"
          v-model="form.regiaoSelecionada"
          :options="regioesPreco"
        />
        <template v-if="!form.regiaoSelecionada">
          <h2>Selecione a região</h2>
          <v-icon size="40" class="pa-2">fa-map</v-icon>
          <p>As configurações de preço da região aparecerão aqui</p>
        </template>
      </Panel>
      <Panel
        v-if="form.regiaoSelecionada"
        lineColor="var(--primary-color)"
        class="mb-2"
      >
        <template v-slot:options>
          <FormButtonGroup align="end" spaced>
            <FormButton
              text="Enviar arquivo de tabela de preço"
              type="default"
              @click="dialog = true"
            />
            <FormButton
              text="Relatório de disponíveis para venda"
              type="primario"
              @click="onDownloadCsv"
            />
          </FormButtonGroup>
        </template>
        <DataTable
          :headers="headersProduct"
          :endpoint="endpointProduct"
          :filters="filtersProduct"
          noHideFilters
          :auto-search="false"
          sortBy="nome"
          :sortDesc="false"
          ref="datatableProduct"
          classe="elevation-0"
          :limitPage="[10, 25, 50, 100, 200]"
        >
          <template v-slot:disponivelvenda="{ row }">
            <FormCheckbox
              v-model="row.disponivelvenda"
              class="my-0 py-0 pt-2 mb-n5"
              @change="updateLocalProducts(row)"
              :disabled="disableCheckbox(row)"
            />
          </template>
          <template v-slot:nome="{ row }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-if="row.tipounidade == 'UN'"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  color="info"
                  label
                  small
                >
                  <v-icon size="18">
                    fa-barcode
                  </v-icon>
                </v-chip>
                <v-chip
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  label
                  small
                >
                  <v-icon size="18">
                    fa-balance-scale
                  </v-icon>
                </v-chip>
              </template>
              <span>{{
                row.tipounidade == 'UN'
                  ? 'Produto vendido em unidades'
                  : 'Produto pesado'
              }}</span>
            </v-tooltip>
            {{ row.nome }}
            {{ row.tipo_produto == 'CONJUNTO' ? '(CONJUNTO)' : '' }}
            <small class="float-right" v-if="row.codigobarras">{{
              row.codigobarras
            }}</small>
          </template>
          <template v-slot:codigointerno="{ row }">
            <FormField
              v-if="!row.codigobarras"
              v-model.number="row.codigointerno"
              class="mb-n5 mt-2"
              @blur="updateLocalProducts(row)"
            />
            <span v-else>{{ row.codigointerno }}</span>
          </template>
          <template v-slot:precovenda="{ row }">
            <FormField
              v-model.number="row.precovenda"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateLocalProducts(row)"
            />
          </template>
          <template v-slot:precovendaatacado="{ row }">
            <FormField
              v-model.number="row.precovendaatacado"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateLocalProducts(row)"
            />
          </template>
          <template v-slot:minvendaatacado="{ row }">
            <FormField
              v-model.number="row.minvendaatacado"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateLocalProducts(row)"
            />
          </template>
          <template v-slot:maxporpedido="{ row }">
            <FormField
              v-model.number="row.maxporpedido"
              typeField="number"
              class="mb-n5 mt-2"
              @blur="updateLocalProducts(row)"
            />
          </template>
          <template v-slot:status="{ row }">
            <v-row v-for="(status, i) in getStatus(row)" :key="i">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" :color="status.color" v-on="on">{{
                    status.icon
                  }}</v-icon>
                </template>
                {{ status.help }}
              </v-tooltip>
              <span class="ml-3">{{ status.label }}</span>
            </v-row>
          </template>
        </DataTable>
      </Panel>
    </template>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import FormSwitch from '@/components/form/FormSwitch'
import FormSelect from '@/components/form/FormSelect'
import FormField from '@/components/form/FormField'
import FormFile from '@/components/form/FormFile'
import SelectCliente from '@/components/select/SelectCliente'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  GRUPO_PRECO_REMOVE,
  GRUPO_PRECO_UPDATE_PADRAO
} from '@/store/actions/grupoPreco'
import {
  TABELA_PRECO_UPDATE_CLIENTE,
  TABELA_PRECO_LOAD_CLIENTE,
  TABELA_PRECO_LOAD_TIPO_GRUPO,
  TABELA_PRECO_UPDATE_PRECOS_LOCAL,
  TABELA_PRECO_UPLOAD_LOCAL,
  TABELA_PRECO_STATUS_UPLOAD,
  TABELA_PRECO_DOWNLOAD_LOCAL_CRITICAS
} from '@/store/actions/tabelaPreco'
import { AREA_VENDA_FORNECEDOR_LOAD } from '@/store/actions/areaVenda'

export default {
  name: 'TabelaPrecoTable',
  components: {
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    FormCheckbox,
    FormRadioGroup,
    FormSelect,
    FormField,
    FormFile,
    SelectCliente,
    FormSwitch,
    MarketingModules
  },
  data: () => ({
    dialog: false,
    tipoGrupo: ['CNPJ', 'CPF', 'DESLOGADO'],
    headers: [
      { text: 'Padrão', value: 'tipo_grupo', clickable: false },
      { text: 'Nome', value: 'descricao', clickable: false },
      { text: 'Detalhes', value: 'descricao_longa', clickable: false },
      { text: 'Código', value: 'codigo_grupo_preco', clickable: false },
      {
        text: 'Clientes Vínculados',
        value: 'quant_clientes',
        clickable: false,
        align: 'center'
      },
      {
        text: 'Editar CEPs',
        value: 'ceps',
        clickable: false,
        align: 'center',
        sortable: false
      },
      {
        text: 'Produtos Vínculados',
        value: 'produtos',
        clickable: false,
        align: 'center',
        sortable: false
      },
      {
        text: 'Opções',
        value: 'idgrupo',
        clickable: false,
        align: 'center',
        sortable: false
      }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    headersProduct: [
      { text: 'Disponível', value: 'disponivelvenda', sortable: false },
      { text: 'Produto', value: 'nome' },
      { text: 'Código interno', value: 'codigointerno', align: 'center' },
      { text: 'Preço varejo', value: 'precovenda' },
      { text: 'Preço atacado', value: 'precovendaatacado' },
      { text: 'Min. atacado', value: 'minvendaatacado' },
      { text: 'Max. no pedido', value: 'maxporpedido' },
      { text: 'Status do produto', value: 'status', sortable: false }
    ],
    filtersProduct: [
      { label: 'Pesquisar', query: 'textSearch' },
      { label: 'Código de barras', query: 'codbarras' },
      { label: 'Código interno', query: 'codinterno' },
      { label: 'Código grupo de preço', query: 'codgrupopreco' },
      {
        label: 'Status do produto',
        query: 'statusProduto',
        default: 'all',
        options: [
          { text: 'Todos', value: 'all' },
          { text: 'À venda', value: 'a_venda' },
          { text: 'Sem preço', value: 'sem_preco' },
          { text: 'Aguardando cadastro', value: 'aguardando_cadastro' },
          { text: 'Indisponível', value: 'indisponivel' }
        ],
        clearable: 'false'
      },
      {
        label: 'Preço varejo inicial',
        query: 'precoVarejoInicio',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Preço varejo final',
        query: 'precoVarejoFim',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Preço atacado inicial',
        query: 'precoAtacadoInicio',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Preço atacado final',
        query: 'precoAtacadoFim',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Mín. atacado inicial',
        query: 'minAtacadoInicio',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Mín. atacado final',
        query: 'minAtacadoFim',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Máx. pedido inicial',
        query: 'maxPedidoInicio',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Máx. pedido final',
        query: 'maxPedidoFim',
        type: 'number',
        clearable: 'false'
      },
      {
        label: 'Tipo de cadastro',
        query: 'tipoCadastro',
        default: 'todos',
        options: [
          { text: 'Todos', value: 'todos' },
          { text: 'Com código interno', value: 'codigointerno' },
          { text: 'Com código de barras', value: 'codigobarras' }
        ],
        clearable: 'false'
      }
    ],
    codigo_grupo_preco: '',
    endpointFilter: '',
    loadingRow: {},
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ],
    clientePadrao: {
      usar: false,
      isEditando: true,
      defaultSelect: []
    },
    regioesPreco: [],
    form: {
      regiaoSelecionada: ''
    },
    radioClientePadrao: [
      { label: 'Usar', value: true },
      { label: 'Não usar', value: false }
    ]
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'moduloFornecedorAtivo',
      'getFornecedorTag'
    ]),
    endpoint() {
      return `/api/v4/tabela-preco/${this.getFornecedorId}/datatable`
    },
    endpointClient() {
      return `/api/v4/client/${this.getFornecedorId}/search/options?page=1&perpage=20`
    },
    endpointProduct() {
      return `/api/v1/fornecedor/${this.getFornecedorId}/tabelaprecos-datatable?idarea=${this.form.regiaoSelecionada}`
    },
    endpointProductCSV() {
      return `/api/v1/fornecedor/${this.getFornecedorId}/tabelaprecos-export?idarea=${this.form.regiaoSelecionada}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    usesDelegatedPriceStorage() {
      return this.getFornecedorTag['uses-delegated-price-storage']
    },
    filters() {
      return [
        {
          label: 'Filtrar tabela do cliente',
          query: 'cliente',
          type: 'autocomplete',
          request: {
            value: 'idcliente',
            text: 'nome',
            endpoint: `/api/v4/client/list/by-criteria/${this.getFornecedorId}`,
            query: 'search'
          }
        }
      ]
    }
  },
  methods: {
    onClickClient(item) {
      this.$router.push(`tabela-de-precos/${item.codigo_grupo_preco}/cliente`)
    },
    onClickProduct(item) {
      this.$router.push(`tabela-de-precos/${item.codigo_grupo_preco}/produto`)
    },
    onClickCep(item) {
      this.$router.push(`tabela-de-precos/${item.codigo_grupo_preco}/cep`)
    },
    onNew() {
      this.$router.push('tabela-de-precos/novo')
    },
    onClose() {
      this.$refs.datatable.reload()
      this.loadingRow = {}
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onSelectFile(file) {
      const size = 100 * 1024 * 1024
      if (file.size > size) {
        this.$vueOnToast.pop(
          'info',
          'Arquivo muito grande, pode levar alguns minutos para processar!'
        )
      }
      this.form.currentFile = file
    },
    onSubmit() {
      const formData = new FormData()
      formData.append('idfornecedor', this.getFornecedorId)
      formData.append('idarea', this.form.regiaoSelecionada)
      formData.append('file', this.form.currentFile)
      this.$store
        .dispatch(TABELA_PRECO_UPLOAD_LOCAL, formData)
        .then(response => {
          if (response && response.status == 'success') {
            this.$store
              .dispatch(TABELA_PRECO_STATUS_UPLOAD, response.idprocessamento)
              .then(res => {
                if (res && res.status == 'LIDO') {
                  this.$vueOnToast.pop(
                    'success',
                    'Efetuando o download com as críticas da integração'
                  )
                  this.$store
                    .dispatch(
                      TABELA_PRECO_DOWNLOAD_LOCAL_CRITICAS,
                      `${response.idprocessamento}.csv`
                    )
                    .then(r => {
                      const csvUrl = window.URL.createObjectURL(new Blob([r]))
                      const link = document.createElement('a')
                      link.href = csvUrl
                      link.setAttribute('download', 'criticas.csv')
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                      this.dialog = false
                    })
                    .catch(err => {
                      const erro =
                        err.response.data.Mensagem ||
                        err.response.data ||
                        'Erro ao buscar status do upload de tabela de preços'
                      this.$vueOnToast.pop('error', erro)
                    })
                } else if (res.status == 'LIDOF') {
                  this.$vueOnToast.pop(
                    'success',
                    'O Arquivo foi processado com sucesso'
                  )
                } else if (res.status == 'FALHA') {
                  this.$vueOnToast.pop('error', res.mensagem)
                }
              })
              .catch(err => {
                const erro =
                  err.response.data.Mensagem ||
                  err.response.data ||
                  'Erro ao buscar criticas de upload de tabela de preços'
                this.$vueOnToast.pop('error', erro)
              })
          } else {
            this.$vueOnToast.pop('error', response.message)
          }
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Erro ao enviar tabela de preços'
          this.$vueOnToast.pop('error', erro)
        })
    },
    onDelete(item) {
      const options = {
        text:
          'Se existirem clientes vinculados a esta tabela de preços eles serão movidos para a tabela padrão.',
        title: `Você confirma a exclusão do grupo de preço '${item.codigo_grupo_preco}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.codigo_grupo_preco]: true
      }
      this.$store
        .dispatch(GRUPO_PRECO_REMOVE, item.codigo_grupo_preco)
        .then(response => {
          this.$vueOnToast.pop('success', response)
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.codigo_grupo_preco]: false
          }
        })
        .catch(() => {
          this.loadingRow = {
            ...this.loadingRow,
            [item.codigo_grupo_preco]: false
          }
        })
    },
    updateGrupoPadrao(item, grupoPadrao) {
      if (item.tipo_grupo.includes(grupoPadrao)) {
        this.$vueOnToast.pop(
          'warning',
          `Não é possível remover o tipo ${grupoPadrao}. Para alterá-lo, por favor, selecione outra tabela de preços com o tipo correspondente.`
        )
        this.$refs.datatable.reload()
        return
      }
      this.loadingRow = {
        ...this.loadingRow,
        [item.codigo_grupo_preco]: true
      }
      let tipoGrupo = item.tipo_grupo
      tipoGrupo.push(grupoPadrao)
      const data = {
        codigoGrupo: item.codigo_grupo_preco,
        grupoPadrao: grupoPadrao,
        tipo_grupo: tipoGrupo,
        itensConfiguracao: []
      }
      this.$store
        .dispatch(GRUPO_PRECO_UPDATE_PADRAO, data)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Grupo de preço padrão atualizado com sucesso!'
          )
          this.$refs.datatable.reload()
          this.loadingRow = {
            ...this.loadingRow,
            [item.codigo_grupo_preco]: false
          }
        })
        .catch(err => {
          this.loadingRow = {
            ...this.loadingRow,
            [item.codigo_grupo_preco]: false
          }
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Erro ao atualizar grupo de preço'
          this.$vueOnToast.pop('error', erro)
        })
    },
    onUpdateClienteReferencia() {
      const { usar, defaultSelect, cliente } = this.clientePadrao
      const idcliente = cliente && cliente.idcliente
      if (idcliente || !usar) {
        if (!defaultSelect.length && usar) {
          this.$vueOnToast.pop(
            'error',
            'Você precisa informar ao menos um tipo de situação para a tabela de preço!'
          )
          return
        }
        if (!usar) {
          this.removerInformacoes()
        }
        this.$store
          .dispatch(TABELA_PRECO_LOAD_TIPO_GRUPO)
          .then(tipoGrupo => {
            const tipoGrupos = tipoGrupo.length ? tipoGrupo[0].tipo_grupo : []
            const gruposFaltantes = tipoGrupos.filter(
              tg => !defaultSelect.map(ds => ds.toUpperCase()).includes(tg)
            )
            if (!gruposFaltantes.length) {
              this.updateClienteReferencia()
            } else {
              const erro = `Não foi possível salvar o cliente de referência, pois estão 
                faltando os seguintes tipos de grupo: ${gruposFaltantes.join(
                  ','
                )}, 
                ao qual o grupo do cliente está vínculado. Para continuar, por favor, 
                selecione os critérios informados. Obs: caso você não queira o tipo 
                de grupo ${gruposFaltantes.join(
                  ','
                )} em Cliente de referência, você 
                pode selecionar entre os demais grupos de preço.`
              this.$vueOnToast.pop('error', erro)
            }
          })
          .catch(() => {
            const erro = 'Falha na busca de tipo de grupos'
            this.$vueOnToast.pop('error', erro)
          })
      } else {
        this.$vueOnToast.pop(
          'warning',
          'É necessário selecionar um cliente antes de salvar.'
        )
      }
    },
    updateClienteReferencia() {
      const idcliente = this.clientePadrao.cliente
        ? this.clientePadrao.cliente.idcliente
        : null
      const data = {
        idcliente: this.clientePadrao.usar ? idcliente : null,
        mostrarPoliticasModoAnonimo: `${this.clientePadrao.mostrarPoliticasModoAnonimo}`,
        mostrarPoliticasrClientesSemGrupo: `${this.clientePadrao.mostrarPoliticasrClientesSemGrupo}`,
        defaultSelect: this.clientePadrao.defaultSelect
      }
      this.$store
        .dispatch(TABELA_PRECO_UPDATE_CLIENTE, data)
        .then(response => {
          this.clientePadrao.isEditando = response ? false : true
          this.$vueOnToast.pop('success', 'Cliente padrão salvo com sucesso!')
          this.$refs.datatable.reload()
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Erro ao salvar cliente padrão'
          this.$vueOnToast.pop('error', erro)
        })
    },
    cancelarClienteReferencia() {
      const { isEditando, cliente, usar } = this.clientePadrao
      if (isEditando && usar) {
        this.getDefaultClient()
      }
      if (usar) {
        if (cliente) {
          this.setClientePadrao(true, true)
        } else {
          this.setClientePadrao(false, false)
          this.removerInformacoes()
        }
      } else if (cliente) {
        this.setClientePadrao(true, true)
      }
    },
    setClientePadrao(usar, isEditando) {
      this.clientePadrao.usar = usar
      this.clientePadrao.isEditando = isEditando
    },
    removerInformacoes() {
      this.clientePadrao.cliente = null
      this.clientePadrao.mostrarPoliticasModoAnonimo = false
      this.clientePadrao.mostrarPoliticasrClientesSemGrupo = false
      this.clientePadrao.defaultSelect = []
      this.clientePadrao.mostrarCnpj = false
      this.clientePadrao.mostrarCpf = false
      this.clientePadrao.mostrarDeslogado = false
    },
    getDefaultClient() {
      this.$store
        .dispatch(TABELA_PRECO_LOAD_CLIENTE)
        .then(response => {
          if (response) {
            this.clientePadrao.cliente = response
            this.clientePadrao.mostrarPoliticasModoAnonimo =
              response.mostrarPoliticasModoAnonimo || false
            this.clientePadrao.mostrarPoliticasrClientesSemGrupo =
              response.mostrarPoliticasrClientesSemGrupo || false
            this.clientePadrao.defaultSelect =
              (response.defaultSelect || []).map(ds => ds.toUpperCase()) || []
            this.clientePadrao.telaVisualizacao =
              this.clientePadrao.defaultSelect.length === 0
            this.clientePadrao.usar = !!this.clientePadrao.cliente.idcliente
            this.clientePadrao.isEditando = !this.clientePadrao.usar
          }
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Erro ao buscar cliente padrão'
          this.$vueOnToast.pop('error', erro)
        })
    },
    getAreaVenda() {
      this.$store.dispatch(AREA_VENDA_FORNECEDOR_LOAD).then(response => {
        this.regioesPreco = (response || []).map(v => {
          return {
            ...v,
            text: v.descricao,
            value: v.idareavendafornecedor
          }
        })
      })
    },
    disableCheckbox(item) {
      const {
        maxporpedido,
        minvendaatacado,
        precovenda,
        precovendaatacado
      } = item
      if (maxporpedido || minvendaatacado || precovenda || precovendaatacado) {
        return false
      }
      return true
    },
    getStatus(item) {
      const status = []
      const addStatus = (icon, color, label, help) => {
        status.push({ icon, color, label, help })
      }
      if (!item.disponivelvenda) {
        addStatus(
          'fa fa-circle',
          '#ababab',
          'Indisponível',
          'Você não disponibilizou esse produto para venda'
        )
      }
      if (!item.precovenda || item.precovenda <= 0) {
        addStatus(
          'fa fa-circle',
          '#e77d24',
          'Sem preço',
          'Você não atribuiu preço ao produto'
        )
      }
      if (!item.cadastro_ativo) {
        addStatus(
          'fa fa-circle',
          '#3497dc',
          'Em validação',
          'O produto está esperando aprovação do cadastro pela LifeApps'
        )
      }
      if (status.length === 0) {
        addStatus('fa fa-circle', '#62cb31', 'À venda', 'Produto à venda')
      }
      return status
    },
    updateLocalProducts(item) {
      this.isLoading = true
      const data = {
        idproduto: item.idproduto,
        idcadastroextraproduto: item.idcadastroextraproduto,
        codinterno: item.codigointerno,
        precovarejo: `${item.precovenda}`,
        precovendaatacado: `${item.precovendaatacado}`,
        minvendaatacado: `${item.minvendaatacado}`,
        maxporpedido: `${item.maxporpedido}`,
        disponivel: `${!item.disponivelvenda}`
      }
      this.$store
        .dispatch(TABELA_PRECO_UPDATE_PRECOS_LOCAL, {
          dados: [data],
          idarea: this.form.regiaoSelecionada
        })
        .then(() => {
          this.isLoading = false
          this.loadingRow = {
            ...this.loadingRow,
            [item.idcadastroextraproduto]: false
          }
          this.$vueOnToast.pop(
            'success',
            `Preço do produto ${item.nome} alterada com sucesso`
          )
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha atualizar preço do produto'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    onDownloadCsv() {
      this.$refs.datatableProduct.fullDownloadCsv(this.endpointProductCSV)
    }
  },
  watch: {},
  created() {
    this.getAreaVenda()
    this.getDefaultClient()
  }
}
</script>

<style lang="scss" scoped></style>
